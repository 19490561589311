import React, { useCallback } from 'react';
import { useStoredFile } from '@brainstud/academy-api/Hooks/useStoredFiles';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { StoredFile } from '@brainstud/academy-api/Types/Resources/StoredFile';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Textarea } from '@brainstud/ui/Form Input/Textarea';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import styles from './StoredFileFormModal.module.css';

const cx = classNames.bind(styles);

/**
 * Modal to update a stored file.
 */
export const StoredFileFormModal = ({
  storedFile,
}: {
  storedFile: StoredFile;
}) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { closeModal } = useModals();
  const { invalidateQueries } = useApi();

  const [{ update }] = useStoredFile(
    { storedFile: storedFile.id },
    { enabled: false }
  );

  const handleSubmit = useCallback(
    (values: { title?: string; description?: string }) => {
      update.mutateAsync(
        {
          ...values,
          ...(!values.title ? { title: storedFile.originalFileName } : {}),
          ...(!values.description ? { description: '' } : {}),
          _method: 'PATCH',
        },
        {
          onSuccess: () => {
            setToast(t('views.stored_file.update.success'), 'success');
            closeModal();
            invalidateQueries(['v1.exposition']);
          },
          onError: () => {
            setToast(t('views.stored_file.update.error'), 'error');
          },
        }
      );
    },
    [
      closeModal,
      invalidateQueries,
      setToast,
      storedFile.originalFileName,
      t,
      update,
    ]
  );

  return (
    <SidebarModal
      size="medium"
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      <SidebarModal.Header>
        <div className={cx(styles.header)}>
          <h1>{t('views.stored_file.update.title')}</h1>
          <div>
            <Button
              type="submit"
              loading={update.isPending}
              form="update_stored_file"
            >
              {t('submit')}
            </Button>
          </div>
        </div>
      </SidebarModal.Header>
      <div className={cx(styles.gap)}>
        <span>{t('views.stored_file.update.description')}</span>
        <Form id="update_stored_file" onSubmit={handleSubmit}>
          <div className={cx(styles.gap)}>
            <Input
              type="text"
              label={t('title')}
              name="title"
              defaultValue={storedFile?.title}
            />
            <Textarea
              label={t('description')}
              name="description"
              defaultValue={storedFile?.description}
            />
          </div>
        </Form>
      </div>
    </SidebarModal>
  );
};
