import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  StoredFile,
  StoredFileCreateInput,
  StoredFileDeleteInput,
  StoredFileFilters,
  StoredFileIncludes,
  StoredFileSort,
  StoredFileUpdateInput,
} from '../Types/Resources/StoredFile';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IStoredFileProps
  extends HookParams<
    typeof StoredFileIncludes,
    StoredFileFilters,
    typeof StoredFileSort
  > {
  storedFile?: UUID;
}

/**
 * Access to a single StoredFile.
 * @param storedFile
 * @param queryParameters
 * @param settings
 * @returns StoredFile
 */
export function useStoredFile(
  { storedFile, ...queryParameters }: IStoredFileProps = {},
  settings?: IUseQueryOptions<DataDocument<StoredFile>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: !!storedFile,
      baseName: 'v1.stored_files',
      baseUri: '/v1/stored_files',
      uri: `/${storedFile}`,
      queryParameters,
      invalidate: ['v1.expositions', 'stored_files'],
    },
    context
  );
  return useSingleCRUD<
    StoredFile,
    StoredFileCreateInput,
    StoredFileUpdateInput,
    StoredFileDeleteInput
  >(request, settings);
}
